import Alpine from 'alpinejs';
import './css/app.pcss';

window.Alpine = Alpine;

Alpine.start();

// Import Medium Zoom Library
import 'medium-zoom/dist/medium-zoom';

// Animate On Scroll Options
// https://github.com/michalsnik/aos
import AOS from 'aos';
AOS.init({ duration: 300 });

// Show made by Spaceray Creative banner.
if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
	var n = [
		'\n %c Made with ♥ by Spaceray Creative %c %c %c https://www.spaceraycreative.com/ \n\n',
		'color: #a0d39d; background: #0b4061; padding:5px 0;',
		'background: #4c4c4d; padding:5px 0;',
		'background: #4c4c4d; padding:5px 0;',
		'color: #fff; background: #1c1c1c; padding:5px 0;',
	];
	window.console.log.apply(console, n);
} else
	window.console && window.console.log('Made with love ♥ by Spaceray Creative - https://www.spaceraycreative.com/');
